// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-biobey-jsx": () => import("./../../../src/pages/biobey.jsx" /* webpackChunkName: "component---src-pages-biobey-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-service-edu-jsx": () => import("./../../../src/pages/service-edu.jsx" /* webpackChunkName: "component---src-pages-service-edu-jsx" */),
  "component---src-pages-service-lab-jsx": () => import("./../../../src/pages/service-lab.jsx" /* webpackChunkName: "component---src-pages-service-lab-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

